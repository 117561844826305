import React, { useRef, useState } from 'react'
import './Event.css'
import PageNumbers from './components/PageNumbers'
import { Tour } from 'antd'
import { useSelector } from 'react-redux'
import LeftAndRightBtns from './components/LeftAndRightBtns'
import DropDown from './components/DropDown'

export default function Event1({
  currentPage,
  totalPage,
  handlePrevious,
  handleNext,
  index,
  setIndex,
  selectItems,
  mainColor,
  secondaryColor,
}) {
  const isMobile = useSelector(state => state.content.isMobile)
  const pageNumberRef = useRef(null)
  const [open, setOpen] = useState(
    isMobile && !localStorage.getItem('gift-guide-tour'),
  )
  const steps = [
    {
      // title: 'Welcome',
      description: 'You can swipe left or right to navigate through the pages.',
      cover: (
        <img
          alt='hand-finger-left-right'
          src='/gift-guide/hand-finger-left-right.webp'
        />
      ),
      // description: 'left',
      target: null,
      style: isMobile ? { maxWidth: '95%' } : '',
    },
  ]

  return (
    <div
      className={`relative m-auto flex h-[1280px] w-[768px] flex-col items-center gap-3 py-5 px-10 text-white shadow-lg smsize:h-full smsize:w-full smsize:px-5`}
      style={{
        backgroundImage: 'url(/event/january-bg.png)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPositionY: '-50px',
        backgroundPositionX: 'center',
      }}
    >
      <DropDown
        selectItems={selectItems}
        index={index}
        setIndex={setIndex}
        extraCass={
          'absolute top-2 right-2 rounded-full bg-[#202020] text-[#ffffff] h-[40px] w-[40px]'
        }
      />
      <LeftAndRightBtns
        handlePrevious={handlePrevious}
        handleNext={handleNext}
        currentPage={currentPage}
        totalPage={totalPage}
      />
      <Tour
        open={open}
        onClose={() => {
          localStorage.setItem('gift-guide-tour', 'true')
          setOpen(false)
        }}
        steps={steps}
        style={{ width: '100%' }}
      />
      <div
        className={`w-full text-center text-[20px] uppercase leading-5 text-white`}
      >
        January 2025
        <br />
        COCHRANE, ALBERTA
      </div>

      <div className='my-6 mx-5 flex w-full flex-col items-start justify-start text-left uppercase leading-[100px] smsize:leading-[70px] smsize:my-3 smsize:pb-2'>
        <h1 className='text-left text-[90px] font-light text-[#bee6ff] smsize:text-[70px]'>
          January
        </h1>
        <p
          className={`text-left text-[90px] font-bold smsize:text-[55px] smsize:leading-[65px]`}
        >
          shopping
          <br />
          guide
        </p>
      </div>
      <div className='flex h-[800px] w-full flex-col justify-between py-5 smsize:h-[600px]'>
        <div className='text-left text-[30px] font-light uppercase leading-8 text-[#fffdf6]'>
          {/* your source <strong className='font-bold'>Better</strong>, <br />
          Feel <strong className='font-bold'>Better</strong>: <br />
          Sustainable Gift Ideas */}
        </div>
        <div className='w-full text-right text-[30px] font-light uppercase leading-8 text-[#fffdf6]'>
          cochrane shops
          <br /> to discover
          <br /> in 2025
        </div>
      </div>
      <div className='mb-3 flex w-[220px] items-center justify-center rounded-full bg-[#1c408f] p-3'>
        <a href='https://www.fitzba.com' target='_blank' rel='noreferrer'>
          <img width={150} src='/logo-white.png' alt='Fitzba.com' />
        </a>
      </div>
    </div>
  )
}
