import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

export default function SeeMoreDetails({
    item_id
}) {
    const isMobile = useSelector(state => state.content.isMobile)
    return (
        <>
            {
                isMobile ? (
                    <Link to={`/item/${item_id}?ref=winter-shopping-guide`} className='uppercase text-[#8f7678] underline smsize:text-[14px]'
                    >
                        See more details
                    </Link>
                ) :
                    (
                        <a className='uppercase text-[#8f7678] underline smsize:text-[14px]'
                            href={`${window.location.origin}/item/${item_id}?ref=winter-shopping-guide`}
                            target='_blank'
                            rel='noreferrer'
                        >
                            See more details
                        </a>
                    )
            }
        </>
    )
}
