import React from 'react'
import './Event.css'
import { contentCode } from '@data/contentCode.data'
import ContentDataHook from '@hooks/ContentData.hook'
import Loading from '@components/Loading'
import PageTemplate from './components/PageTemplate'

export default function Event2({
  currentPage,
  totalPage,
  handlePrevious,
  handleNext,
  index,
  setIndex,
  selectItems,
  mainColor,
  secondaryColor,
}) {
  const {
    isPending,
    error,
    data: content,
  } = ContentDataHook(contentCode.WINTER_SHOPPING_GUIDE)
  if (isPending) return <Loading />
  if (error) return 'GiftGuide: An error has occurred: ' + error.message

  return (
    <PageTemplate
      currentPage={currentPage}
      totalPage={totalPage}
      handlePrevious={handlePrevious}
      handleNext={handleNext}
      index={index}
      setIndex={setIndex}
      selectItems={selectItems}
    >
      <div className='w-full'>
        <div className='relative flex w-full flex-col items-center justify-between smsize:pt-[40px]'>
          <h1
            className={`w-full py-[30px] pl-[30px] text-left text-[80px] font-bold uppercase leading-[80px] text-[#1c408f] smsize:top-5 smsize:text-[40px] smsize:leading-[40px]`}
          >
            A message
            <br />
            from
            <br />
            <span className='font-light'>FITZBA</span>
          </h1>
          <img
            src='/event/street-bg.png'
            alt={content?.title}
            className='w-full'
          />
        </div>
        <div>
          <div
            className={`flex h-[60px] w-full items-center justify-start bg-[${mainColor}] smsize:h-[40px] smsize:w-full`}
          >
            <a
              className='w-full text-white underline'
              href='https://www.fitzba.com'
              target='_blank'
              rel='noreferrer'
            >
              www.fitzba.com
            </a>
          </div>
          <div className='mt-3 flex justify-end pr-5 smsize:mt-0 smsize:p-3 smsize:pr-0'>
            <p
              className='font-ligh w-[70%] text-left text-[14px] leading-4 smsize:w-full'
              dangerouslySetInnerHTML={{ __html: content?.content }}
            />
          </div>
        </div>
      </div>
    </PageTemplate>
  )
}
