/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * The Footer component renders the footer of the application.
 * It includes links to various pages of the application, social media links, and a download app button.
 * The component uses the useDispatch hook from the react-redux library to dispatch actions.
 * It also uses the useState hook from the React library to manage the state of the DownloadApp component.
 *
 * @author James Nguyen
 * @version May 09, 2023 
 */
import React, { useEffect } from 'react'
import './Footer.css'
import { Link } from 'react-router-dom'
import DownloadApp from './DownloadApp'
import { useDispatch, useSelector } from 'react-redux'
import { setGotoElement } from '@reducers/contentReducer'
import { Config } from '@Config'
import { Drawer } from 'antd'
import { getOSName } from '@utils/Helper'
import EmailSubscription from './EmailSubscription'
import { CloseOutlined } from '@ant-design/icons'
import CookieSetting from './CookieSetting'


export default function FooterNew() {
  const dispatch = useDispatch()
  const [isOpenDownloadApp, setIsOpenDownloadApp] = React.useState(false)
  const [isOpenInApp, setIsOpenInApp] = React.useState(false)
  const isMobile = useSelector((state) => state.content.isMobile)
  const [osName, setOsName] = React.useState('Unknown OS')
  const [isClicked, setIsClicked] = React.useState(false)
  const [cookiePolicy, setCookiePolicy] = React.useState(false)
  const [isOpenCookieSetting, setIsOpenCookieSetting] = React.useState(false)
  const [cookieSetting, setCookieSetting] = React.useState({
    functionality: true,
    performance: true,
    targeting: true,
  })
  // const [inputValue, setInputValue] = React.useState('')

  useEffect(() => {
    const currentDate = new Date()
    // Format the date as YYYY-MM-DD
    const formattedDate = currentDate.toISOString().split('T')[0]
    const isClickedContinue = localStorage.getItem('isClickedContinue')
    const cookieSetting = localStorage.getItem('cookieSetting')
    if (cookieSetting) {
      setCookieSetting(JSON.parse(cookieSetting))
    } else {
      setCookiePolicy(true)
    }
    // const isClickedContinue = 'false'
    // check current date is more than 7 days from the last clicked continue date
    const diffTime = Math.abs(currentDate - new Date(isClickedContinue || formattedDate))
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
    // if (!isClickedContinue || isClickedContinue !== formattedDate) {
    if (!isClickedContinue || diffDays >= 7) {
      const osName = getOSName()
      setOsName(osName)
      const ref = localStorage.getItem('ref')
      // IOS Webview
      // Android Webview
      setIsOpenInApp(osName !== 'Unknown OS'
        && osName !== 'IOS Webview'
        && osName !== 'Android Webview'
        && ref !== 'ios'
        && ref !== 'android'
        && cookieSetting
      )
    }
  }, [])

  // let osName = 'Unknown OS'

  // const isClickedContinue = localStorage.getItem('isClickedContinue')
  // const isClickedContinue = false
  // if (!isClickedContinue || isClickedContinue !== 'true') {
  //   osName = getOSName()
  //   console.log('osName', osName)
  // }
  const onClickOpenInApp = async () => {
    setIsOpenInApp(false)
    const osName = getOSName()
    if (osName.indexOf('Android') !== -1) {
      window.open(Config.android_download_link, '_blank')
    } else {
      window.open(Config.ios_download_link, '_blank')
      // const appLink = "https://www.fitzba.com" // Universal or App Link
      // const fallbackLink = Config.ios_download_link // App Store or Google Play URL
      // // Redirect user
      // window.location.href = appLink
      // // Fallback after a short delay if app isn't installed
      // setTimeout(() => {
      //   window.location.href = fallbackLink
      // }, 2000) // 2-second delay to allow app to open if installed
    }
  }

  const onCloseCookieSetting = () => {
    localStorage.setItem('cookieSetting',
      JSON.stringify(cookieSetting)
    )
    setCookiePolicy(false)
  }

  return (
    <footer className='flex py-16 flex-col gap-16 smsize:pb-7'>
      <CookieSetting
        isOpen={isOpenCookieSetting}
        setIsOpen={setIsOpenCookieSetting}
        setCookiePolicy={setCookiePolicy}
        cookieSetting={cookieSetting}
        setCookieSetting={setCookieSetting}
      />
      <Drawer
        title={null}
        header={null}
        placement={'bottom'}
        closable={true}
        height={isMobile ? 300 : 200}
        onClose={() => {
          setCookieSetting({
            functionality: true,
            performance: true,
            targeting: true,
          })
          onCloseCookieSetting()
        }}
        styles={{
          header: {
            display: 'none'
          },
          body: {
            textAlign: 'left'
          }
        }}
        open={cookiePolicy}
        key={'cookie-policy'}
      >
        <div className='flex flex-col gap-2'>
          <div className='flex items-center justify-between'>
            <h3>Cookie Policy</h3>
            <CloseOutlined onClick={() => {
              setCookieSetting({
                functionality: true,
                performance: true,
                targeting: true,
              })
              onCloseCookieSetting()
            }} />
          </div>
          <p>At Fitzba.com, we are committed to protecting your privacy and providing you with a transparent and user-friendly experience.
            We use essential cookies to improve and personalize your visit, and analyze our website's performance, but only if you accept.
            Learn more about your choices in <a href={'/cookie-policy'} className='underline' target='_blank' rel="noreferrer">our cookie policy</a>.</p>
          <div className='flex gap-3'>
            <button className='bg-[#E4B456] text-white border rounded-md py-1 px-4'
              onClick={() => {
                setCookieSetting({
                  functionality: true,
                  performance: true,
                  targeting: true,
                })
                onCloseCookieSetting()
              }}
            >
              Accept all cookies
            </button>
            <button className=' border border-[#333] rounded-md py-1 px-4'
              onClick={() => {
                setIsOpenCookieSetting(true)
              }}
            >
              Manage cookies
            </button>
          </div>
        </div>
      </Drawer>
      <Drawer
        title="Open in app"
        placement={'bottom'}
        closable={true}
        height={200}
        onClose={() => {
          console.log('onClose')
          setIsOpenInApp(false)
        }}
        open={isOpenInApp}
        key={'open-in-app'}
      >
        <div className='flex flex-col items-center gap-5'>
          <h3 className='w-full'>Get the Full Experience on the Fitzba App</h3>
          <div className='flex justify-center items-center gap-3'>
            <button className=' border border-[#333] rounded-md py-1 px-4'
              onClick={() => {
                setIsOpenInApp(false)
                // save current date the format is YYYY-MM-DD to isClickedContinue in localStorage
                // Get the current date
                const currentDate = new Date()
                // Format the date as YYYY-MM-DD
                const formattedDate = currentDate.toISOString().split('T')[0]
                localStorage.setItem('isClickedContinue', formattedDate)
              }}>
              Continue
            </button>
            <button className='bg-[#E4B456] text-white border rounded-md py-1 px-4' onClick={onClickOpenInApp}>
              Open in APP
            </button>
          </div>
        </div>
      </Drawer>
      <DownloadApp
        isOpen={isOpenDownloadApp}
        setIsOpen={setIsOpenDownloadApp}
      />
      <div className='flex w-full justify-center'>
        <div className='m-auto w-[1024px] smsize:w-full smsize:px-16 smsize:gap-12 flex justify-between gap-10 smsize:flex-col'>
          <div className='flex-1 flex flex-col gap-7'>
            <img src='/footer-fitzba-logo.png' width={152} height={60} alt='fitzba' />
            <div className='flex gap-4'>
              <a
                target='_blank'
                rel='noreferrer'
                href={Config.socialMedia.instagram}
              >
                <img
                  src='/images/footer_instagram.png'
                  alt='fitzba instagram'
                  width={29}
                  height={29}
                />
              </a>
              <a
                target='_blank'
                rel='noreferrer'
                href={Config.socialMedia.facebook}
              >
                <img src='/images/footer_facebook.png' alt='fitzba facebook' width={29}
                  height={29} />
              </a>
              <a
                target='_blank'
                rel='noreferrer'
                href={Config.socialMedia.linkedin}
              >
                <img src='/images/footer_linkedin.png' alt='fitzba linkedin' width={29}
                  height={29} />
              </a>
            </div>
          </div>
          <div className='flex-1 flex flex-col text-left gap-5 smsize:hidden'>
            <Link to='/about'>About Us</Link>
            <Link to='/contact'>Contact Us</Link>
            <Link to='/faq'>FAQs</Link>
          </div>
          <div className='flex-1 flex flex-col text-left gap-5 smsize:hidden'>
            <Link to='/for-business'>For Business</Link>
            <Link to='/blog'>Blog</Link>
            <Link
              // className='phone:hidden'
              style={{ cursor: 'pointer' }}
              name='getApp'
              onClick={() => setIsOpenDownloadApp(true)}
            >
              Get the app
            </Link>
          </div>
          <div className='flex-1 flex flex-col text-left gap-5 smsize:hidden'>
            <Link
              to='/policy'
              onClick={() => dispatch(setGotoElement('terms'))}
            >
              Terms of Use
            </Link>
            <Link
              to='/policy'
              onClick={() => dispatch(setGotoElement('policy'))}
            >
              Privacy Policy
            </Link>
            <Link
              to='/cookie-policy'
            >
              Cookie Policy
            </Link>
            <EmailSubscription />
          </div>
          <div className='hidden smsize:flex smsize:flex-col gap-5'>
            <div className='hidden smsize:grid smsize:grid-cols-2 smsize:gap-4 text-left'>
              <Link to='/about'>About Us</Link>
              <Link to='/contact'>Contact Us</Link>
              <Link to='/faq'>FAQs</Link>
              <Link to='/for-business'>For Business</Link>
              <Link to='/blog'>Blog</Link>
              <Link
                // className='phone:hidden'
                style={{ cursor: 'pointer' }}
                name='getApp'
                onClick={() => setIsOpenDownloadApp(true)}
              >
                Get the app
              </Link>
              <Link
                to='/policy'
                onClick={() => dispatch(setGotoElement('terms'))}
              >
                Terms of Use
              </Link>
              <Link
                to='/policy'
                onClick={() => dispatch(setGotoElement('policy'))}
              >
                Privacy Policy
              </Link>
              <Link
                to='/cookie-policy'
              >
                Cookie Policy
              </Link>
            </div>
            <EmailSubscription />
          </div>
        </div>
      </div>
      {/* <div className='text-[14px] smsize:text-[13px]'>
        © 2023 Fitzba Technologies Inc. <span onClick={() => setIsClicked(!isClicked)}>All rights reserved.</span> {isClicked && <span>{osName}</span>}
      </div> */}
      <div className='text-[14px] smsize:text-[13px]'>
        © 2023-2025 Fitzba Technologies Inc. <a href="https://www.fitzba.com/path1">All rights reserved.</a>
      </div>
    </footer>
  )
}
