import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSearchParams } from 'react-router-dom'
import { ssoLoginGoogle } from '@services/api.service'
import { useDispatch } from 'react-redux'
import { setUserinfo } from '@reducers/userReducer'
export default function GoogleAuth() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const access_token = searchParams.get('access_token')

    React.useEffect(() => {
        async function loginGoogle(access_token) {
            const res = await ssoLoginGoogle({
                access_token: access_token,
                tmp_id: localStorage.getItem('tmp_id')
            })
            console.log('handleOnSuccess', res)
            dispatch(setUserinfo(res.data))
            localStorage.removeItem('tmp_id')
            navigate('/')
        }
        if (access_token) {
            loginGoogle(access_token)
        }
    }, [access_token, dispatch, navigate])

    return null
}
