import React from 'react'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'
import { useSelector } from 'react-redux'

export default function LeftAndRightBtns({
    handlePrevious,
    handleNext,
    currentPage,
    totalPage
}) {
    // return null
    const isMobile = useSelector(state => state.content.isMobile)
    if (isMobile || !handlePrevious) return null
    return (
        <>
            <button
                onClick={handlePrevious}
                className='absolute top-1/2 left-[-100px] -translate-y-1/2 transform cursor-pointer rounded bg-[#1c408f] px-4 py-2 text-white shadow-md disabled:opacity-50 bg-none'
                disabled={currentPage - 1 === 0}
            >
                <FaArrowLeft />
            </button>
            <button
                onClick={handleNext}
                className='absolute top-1/2 right-[-100px] -translate-y-1/2 transform cursor-pointer rounded bg-[#1c408f] px-4 py-2 text-white shadow-md disabled:opacity-50 bg-none'
                disabled={currentPage - 1 === totalPage - 1}
            >
                <FaArrowRight />
            </button>
        </>
    )
}
