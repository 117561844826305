import React from 'react'

export default function ItemTitle({
    name
}) {
    return (
        // text-lg sm:text-xl md:text-2xl
        <h3 className='text-[35px] smsize:text-[20px] smsize:leading-6 font-medium uppercase leading-8 text-[#1c408f] line-clamp-2 smsize:max-h-[60px]'>
            {/* <h3 className='text-lg sm:text-xl md:text-2xl leading-tight font-medium uppercase  text-[#1c408f] line-clamp-2'> */}
            {name}
        </h3>
    )
}
