import React from 'react'
import './Event.css'
import { getStoreLink } from '@utils/Helper'
import SeeMoreDetails from './components/SeeMoreDetails'
import Logo from './components/Logo'
import Address from './components/Address'
import PhoneNumber from './components/PhoneNumber'
import Price from './components/Price'
import GiftCardButton from './components/GiftCardButton'
import ShopNowButton from './components/ShopNowButton'
import ItemImage from './components/ItemImage'
import ItemTitle from './components/ItemTitle'
import PageTemplate from './components/PageTemplate'

function ItemCard({ item, isLeftAndRight = false, mainColor, secondaryColor }) {
  return (
    <div
      className={`flex ${isLeftAndRight ? 'w-full flex-row' : 'w-[50%] flex-col'} items-center gap-5`}
    >
      <div
        className={`${isLeftAndRight ? 'w-[50%]' : 'w-full'} h-[300px] smsize:h-[200px]`}
      >
        <ItemImage
          image={item?.image}
          name={item.name}
          width='100%'
          height='300px'
          extraCss={'smsize:h-[200px] smsize:w-[99%]'}
        />
      </div>
      <div
        className={`flex ${isLeftAndRight ? 'w-[50%]' : 'w-full pl-5'} flex-col items-start justify-between gap-3 text-left text-[#202020]`}
      >
        <ItemTitle name={item.name} />
        <p
          className='leading-5 line-clamp-5'
          dangerouslySetInnerHTML={{
            __html: item?.gift_guide_text || item?.description,
          }}
        />
        <Price
          salePrice={item?.salePrice}
          priceRange={item?.gift_guide_price_range}
          // priceRange={'Based on Sizing'}
        />
        <SeeMoreDetails item_id={item?._id} />
      </div>
    </div>
  )
}

export default function Temp3a({
  business,
  currentPage,
  totalPage,
  handlePrevious,
  handleNext,
  selectItems,
  index,
  setIndex,
  mainColor,
  secondaryColor,
}) {
  const storeLink = getStoreLink(
    business?.shortLink,
    business?.city,
    business?.province,
    business?.name,
    'gift-guide',
  )
  return (
    <PageTemplate
      business={business}
      currentPage={currentPage}
      totalPage={totalPage}
      handlePrevious={handlePrevious}
      handleNext={handleNext}
      selectItems={selectItems}
      index={index}
      setIndex={setIndex}
    >
      <div className='relative flex flex-col gap-3 p-5'>
        <div className='flex gap-5'>
          <Logo
            logo={business?.logo}
            name={business?.name}
            storeLink={storeLink}
          />
          <div className='flex flex-col gap-1 text-left'>
            <h1
              className={`z-10 mb-2 whitespace-normal break-normal break-words text-[45px] font-bold leading-7 text-[${mainColor}] smsize:w-full smsize:text-[30px]`}
            >
              {business?.name}
            </h1>
            <Address business={business} />
            <PhoneNumber business={business} />
          </div>
        </div>
        <p
          className='w-[90%] text-left text-[20px] font-light leading-7 line-clamp-4 smsize:w-full smsize:text-[16px] smsize:leading-5 smsize:line-clamp-5'
          dangerouslySetInnerHTML={{
            __html: business?.shortDesc || business?.description,
          }}
        />
      </div>
      <div
        className={`flex w-full items-center justify-end gap-5 bg-[${mainColor}] px-10 py-3 smsize:justify-center smsize:px-0`}
      >
        <GiftCardButton
          giftCardLink={business?.giftCardLink}
          storeLink={storeLink}
          bgColor={mainColor}
          borderColor={secondaryColor}
          textColor={secondaryColor}
        />
        <ShopNowButton
          storeLink={storeLink}
          bgColor={secondaryColor}
          borderColor={secondaryColor}
          textColor={mainColor}
          extraCss={'bg-white'}
        />
      </div>
      <div className='flex py-3'></div>
      {business?.items?.length > 0 && (
        <ItemCard
          item={business?.items[0]}
          isLeftAndRight={true}
          mainColor={mainColor}
          secondaryColor={secondaryColor}
        />
      )}
      <div className='flex py-3'></div>
      <div className='flex w-full justify-between gap-5'>
        {business?.items?.length > 1 && (
          <ItemCard
            item={business?.items[1]}
            isLeftAndRight={false}
            mainColor={mainColor}
            secondaryColor={secondaryColor}
          />
        )}
        {business?.items?.length > 2 && (
          <ItemCard
            item={business?.items[2]}
            isLeftAndRight={false}
            mainColor={mainColor}
            secondaryColor={secondaryColor}
          />
        )}
      </div>
    </PageTemplate>
  )
}
