import React from 'react'
import './Event.css'
import { getStoreLink } from '@utils/Helper'
import SeeMoreDetails from './components/SeeMoreDetails'
import Logo from './components/Logo'
import Address from './components/Address'
import PhoneNumber from './components/PhoneNumber'
import Price from './components/Price'
import GiftCardButton from './components/GiftCardButton'
import ShopNowButton from './components/ShopNowButton'
import ItemImage from './components/ItemImage'
import ItemTitle from './components/ItemTitle'
import PageTemplate from './components/PageTemplate'

function ItemCard({ item, mainColor, secondaryColor }) {
  return (
    <div className='flex h-[350px] w-full justify-start gap-5 smsize:h-[250px]'>
      <div className='flex w-[50%]'>
        <div
          className={`w-[50px] flex-shrink-0 bg-[${mainColor}] smsize:hidden smsize:w-[20px]`}
        >
          {' '}
        </div>
        <ItemImage
          image={item?.image}
          name={item.name}
          width='100%'
          height='350px'
          extraCss={'w-full smsize:w-full smsize:h-[250px]'}
        />
      </div>
      <div className='flex w-[50%] flex-col items-start justify-start gap-3 text-left text-[#202020]'>
        <ItemTitle name={item.name} />
        <p
          className='pr-4 font-light leading-5 line-clamp-5'
          dangerouslySetInnerHTML={{
            __html: item?.gift_guide_text || item?.description,
          }}
        />
        <Price
          salePrice={item?.salePrice}
          priceRange={item?.gift_guide_price_range}
        />
        <SeeMoreDetails item_id={item?._id} />
      </div>
    </div>
  )
}

export default function Temp2b({
  business,
  currentPage,
  totalPage,
  handlePrevious,
  handleNext,
  selectItems,
  index,
  setIndex,
  mainColor,
  secondaryColor,
}) {
  const storeLink = getStoreLink(
    business?.shortLink,
    business?.city,
    business?.province,
    business?.name,
    'gift-guide',
  )
  return (
    <PageTemplate
      currentPage={currentPage}
      totalPage={totalPage}
      handlePrevious={handlePrevious}
      handleNext={handleNext}
      index={index}
      setIndex={setIndex}
      selectItems={selectItems}
    >
      <div className='relative flex flex-col gap-10 px-10 py-10 smsize:p-5'>
        <div className='flex gap-5'>
          <div className='relative flex-1'>
            <Logo
              logo={business?.logo}
              name={business?.name}
              storeLink={storeLink}
            />
          </div>
          <div className='flex flex-col gap-3 text-left'>
            <h1
              className={`z-10 whitespace-normal break-normal break-words text-[45px] font-bold leading-7 text-[${mainColor}] smsize:w-full smsize:text-[30px]`}
            >
              {business?.name}
            </h1>
            <Address business={business} />
            <PhoneNumber business={business} />
            <p
              className='w-[90%] text-left text-[20px] font-light leading-7 line-clamp-4 smsize:w-full smsize:text-[16px] smsize:leading-5 smsize:line-clamp-5'
              dangerouslySetInnerHTML={{
                __html: business?.shortDesc || business?.description,
              }}
            />
            <div className='flex w-full items-center justify-start gap-5 smsize:absolute smsize:bottom-[-30px] smsize:left-5 smsize:w-auto'>
              <ShopNowButton
                storeLink={storeLink}
                bgColor={mainColor}
                borderColor={mainColor}
                textColor={secondaryColor}
              />
              <GiftCardButton
                giftCardLink={business?.giftCardLink}
                storeLink={storeLink}
                bgColor={secondaryColor}
                borderColor={mainColor}
                textColor={mainColor}
              />
            </div>
          </div>
        </div>
      </div>

      <div className='flex py-5'></div>
      {business?.items?.length > 0 && <ItemCard item={business?.items[0]} mainColor={mainColor} secondaryColor={secondaryColor} />}
      <div className='relative flex w-full py-5 smsize:py-3'></div>
      {business?.items?.length > 1 && <ItemCard item={business?.items[1]} mainColor={mainColor} secondaryColor={secondaryColor} />}
    </PageTemplate>
  )
}
