import React from 'react'
import './GiftGuide.css'
import { contentCode } from '@data/contentCode.data'
import ContentDataHook from '@hooks/ContentData.hook'
import Loading from '@components/Loading'
import Header from './components/Header'
import PageNumbers from './components/PageNumbers'
import LeftAndRightBtns from './components/LeftAndRightBtns'
import DropDown from './components/DropDown'
import GiftGuideTemplate from './components/GiftGuideTemplate'
import { Link } from 'react-router-dom'

export default function GiftGuideContest({
  currentPage,
  totalPage,
  handlePrevious,
  handleNext,
  index,
  setIndex,
  selectItems,
}) {
  const {
    isPending,
    error,
    data: content,
  } = ContentDataHook(contentCode.GIFT_GUIDE_DESC)
  if (isPending) return <Loading />
  if (error) return 'GiftGuide: An error has occurred: ' + error.message

  return (
    <GiftGuideTemplate
      currentPage={currentPage}
      totalPage={totalPage}
      handlePrevious={handlePrevious}
      handleNext={handleNext}
      index={index}
      setIndex={setIndex}
      selectItems={selectItems}
    >
      <div className='w-full'>
        <div className='relative flex w-full items-center justify-between pt-[20px] pr-5 smsize:pt-[40px]'>
          <div className='flex w-full flex-col gap-3 p-5 text-left'>
            <h1 className='text-[35px]'>2024 Fitzba Contest</h1>
            <p>
              Join for Free Today and be entered to win a $500 Shopping Spree!
              <br />
              <a
                href='https://www.fitzba.com/blog/2024-fitzba-contest'
                className='underline'
              >
                Learn more
              </a>
            </p>
            <div className='w-full'>
              <Link to='/register?ref=contest'>
                <img
                  src='/gift-guide/contest.png'
                  className='w-full'
                  alt='2024 Fitzba Contest'
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </GiftGuideTemplate>
  )
}
