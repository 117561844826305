/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * ItemDetailMobile is a functional React component that displays detailed information about an item on mobile devices.
 * The information includes the item's name, images, and whether it's used.
 * It also includes a link to continue shopping, a popup for reserving the item, and a bar for continuing shopping.
 * The component also displays information about the store that sells the item, reviews of the item, and similar items.
 *
 * @author Yang Ming, James Nguyen
 * @version January 22nd, 2024
 */

import React, { useEffect, useState } from 'react'
import { useParams, Link, useSearchParams, useNavigate } from 'react-router-dom'
import { getItemById } from '@services/api.service'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentItem, setGotoScrollY } from '@reducers/contentReducer'
import StoreInfo from './components/StoreInfo'
import ShoppingCartHook from '@hooks/ShoppingCart.hook'
import Loading from '../../components/Loading'
import ItemInfo from './components/ItemInfo'
import Review from './components/Review'
import { AiOutlineLeft } from 'react-icons/ai'
import ItemHeader from './components/ItemHeader'
import { HelmetProvider, Helmet } from 'react-helmet-async'
import { Config } from '@Config'
import ReservePopup from './components/ReservePopup'
import Similars from './components/Similars'
import ItemContinueShippingBar from './components/ItemContinueShippingBar'
import ItemDetailMobile from './ItemDetailMobile'
import ImageList from './components/ImageList'
import { getStoreLink } from 'utils/Helper'

/**
 * Displays detailed information about an item on mobile devices.
 *
 * @param {Object} props The properties passed to the component.
 * @returns {JSX.Element} The rendered component.
 */

export default function ItemDetail() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const item = useSelector(state => state.content.currentItem)
  const location = useSelector(state => state.user.location)
  const saveScrollY = useSelector(state => state.content.saveScrollY)
  const isMobile = useSelector(state => state.content.isMobile)
  const [imageIndex, setImageIndex] = useState(0)
  const [opened, setOpened] = useState(false)
  const { id } = useParams()
  const userinfo = useSelector(state => state.user.userinfo)
  const [isLoading, setIsLoading] = useState(false)
  const [searchParams] = useSearchParams()
  const [selectedVariant, setSelectedVariant] = useState(null)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    const isReserve = searchParams.get('reserve')
    if (isReserve) {
      setOpened(isReserve)
    }
  }, [searchParams])

  // state for the button to toggle
  const [showContinueShoppingBar, setShowContinueShoppingBar] =
    React.useState(false)
  // listen for a scroll activity made by the user
  window.addEventListener('scroll', () => {
    // show the back to top button if the user scroll past 200 px from the top
    setShowContinueShoppingBar(window.scrollY > 180 && opened === false)
  })

  const { addToCart } = ShoppingCartHook()
  // const shoppingCart = useSelector(state => state.user.shoppingCart)

  useEffect(() => {
    if (isLoading) return
    setIsLoading(true)
    const fetchItem = async () => {
      try {
        const res = await getItemById(id, {
          lat: location?.lat,
          lng: location?.lng,
        })
        const item = res.data
        console.log('get item info', res.data)
        dispatch(setCurrentItem(item))
        let variant = null
        for (const element of item?.variants) {
          if (element?.quantity > 0) {
            variant = element
            break
          }
        }
        setSelectedVariant(variant)

        const isPickup = searchParams.get('pickup') === 'true' ? true : false
        if (isPickup && isMobile) {
          setTimeout(() => {
            const pickupIcon = document.getElementById('item-image')
            pickupIcon?.scrollIntoView({ behavior: 'instant' })
          }, 1000)
        }

      } catch (error) {
        console.log('get item info', error)
      }
      setIsLoading(false)
    }
    fetchItem()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id, location])

  if (!item || isLoading) return <Loading />

  const storeLink = getStoreLink(item?.business?.shortLink, item?.business?.city, item?.business?.province, item?.business?.name)
  console.log('storeLink', storeLink)
  function onClickContinueShopping() {
    const ref = searchParams.get('ref')
    if (ref === 'gift-guide') {
      window.location.href = `/cochrane-ab/holiday-gift-guide/${item?.business?.shortLink}`
      return
    } else if (ref === 'winter-shopping-guide') {
      window.location.href = `/cochrane-ab/winter-shopping-guide/${item?.business?.shortLink}`
      return
    }
    dispatch(setGotoScrollY(saveScrollY))
    navigate(-1)
    // navigate('/search')
  }

  const onClickReserve = () => {
    setOpened(true)
    setShowContinueShoppingBar(false)
  }

  // get header's height by id, the id is header
  const headerHeight = document.getElementById('header')?.offsetHeight

  if (isMobile)
    return (
      <ItemDetailMobile
        item={item}
        opened={opened}
        setOpened={setOpened}
        userinfo={userinfo}
        showContinueShoppingBar={showContinueShoppingBar}
        onClickContinueShopping={onClickContinueShopping}
        headerHeight={headerHeight}
        onClickReserve={onClickReserve}
        addToCart={addToCart}
        selectedVariant={selectedVariant}
        setSelectedVariant={setSelectedVariant}
      />
    )

  return (
    <HelmetProvider>
      <ReservePopup
        item={item}
        opened={opened}
        setOpened={setOpened}
        userinfo={userinfo}
        selectedVariant={selectedVariant}
      />
      <Helmet>
        <title>
          {item?.name} - {item?.business?.name} - {Config.appName}
        </title>
      </Helmet>
      {showContinueShoppingBar && (
        <ItemContinueShippingBar
          headerHeight={headerHeight}
          item={item}
          onClickContinueShopping={onClickContinueShopping}
        />
      )}
      <div className='w-full pt-[50px]'>
        <div className='m-auto w-[1280px] flex gap-10'>
          <div className='flex flex-col items-center'>
            <div className='w-fit'>
              <Link
                onClick={onClickContinueShopping}
                // to='/search'
                className='mb-[10px] w-fit cursor-pointer items-center justify-center gap-[5px] text-[16px] uppercase text-[#727272] flex'
              >
                <AiOutlineLeft /> Continue shopping
              </Link>
              <ImageList
                userinfo={userinfo}
                item={item}
                isMobile={isMobile}
                name={item?.name}
                images={item?.images}
                isUsed={item?.isUsed}
                imageIndex={imageIndex}
                setImageIndex={setImageIndex}
              />
            </div>
          </div>
          <div className='flex flex-1 flex-col justify-start gap-0'>
            <ItemHeader item={item} />
            <ItemInfo
              item={item}
              onClickReserve={onClickReserve}
              imageIndex={imageIndex}
              setImageIndex={setImageIndex}
              selectedVariant={selectedVariant}
              setSelectedVariant={setSelectedVariant}
              userinfo={userinfo}
            />
          </div>
        </div>
        <div className='m-auto w-[1280px] pt-20'>
          <StoreInfo info={item?.business} />
          <div className='mt-[75px] flex flex-col px-4 py-5 sm:flex-row'>
            <Review item={item} />
            <Similars id={id} userinfo={userinfo} addToCart={addToCart} />
          </div>
        </div>
      </div>
    </HelmetProvider>
  )
}
