import React from 'react'
import './Event.css'
import { getStoreLink } from '@utils/Helper'
import SeeMoreDetails from './components/SeeMoreDetails'
import Logo from './components/Logo'
import Address from './components/Address'
import PhoneNumber from './components/PhoneNumber'
import Price from './components/Price'
import GiftCardButton from './components/GiftCardButton'
import ShopNowButton from './components/ShopNowButton'
import ItemImage from './components/ItemImage'
import ItemTitle from './components/ItemTitle'
import PageTemplate from './components/PageTemplate'

function ItemCard({ item, mainColor, secondaryColor }) {
  return (
    <div className='flex w-full items-start justify-start gap-5'>
      <table className='m-0 w-[50%] p-0 smsize:w-full'>
        <tbody>
          <tr className='m-0 p-0'>
            <td className='m-0 p-0'>
              <ItemImage
                image={item?.image}
                name={item.name}
                width='100%'
                height='260px'
                extraCss={'w-full smsize:w-full smsize:h-[200px]'}
              />
            </td>
            <td className={`w-[50px] bg-[${mainColor}] smsize:w-[20px]`}></td>
          </tr>
        </tbody>
      </table>
      <div className='flex w-[50%] flex-col justify-start gap-3 pr-5 text-left text-[#202020] smsize:w-full'>
        <ItemTitle name={item.name} />
        <Price
          salePrice={item?.salePrice}
          priceRange={item?.gift_guide_price_range}
        />
        <p
          className='leading-5 line-clamp-5'
          dangerouslySetInnerHTML={{
            __html: item?.gift_guide_text || item?.description,
          }}
        />
        <SeeMoreDetails item_id={item?._id} />
      </div>
    </div>
  )
}

export default function Temp3c({
  business,
  currentPage,
  totalPage,
  handlePrevious,
  handleNext,
  selectItems,
  index,
  setIndex,
  mainColor,
  secondaryColor,
}) {
  const storeLink = getStoreLink(
    business?.shortLink,
    business?.city,
    business?.province,
    business?.name,
    'gift-guide',
  )
  return (
    <PageTemplate
      currentPage={currentPage}
      totalPage={totalPage}
      handlePrevious={handlePrevious}
      handleNext={handleNext}
      index={index}
      setIndex={setIndex}
      selectItems={selectItems}
    >
      <div className='relative flex flex-col gap-3 p-5 pb-5 smsize:pb-5'>
        <div className='flex justify-between gap-3 smsize:flex-col'>
          <div className='flex w-full flex-1 flex-col justify-between gap-5'>
            <div className='flex items-start gap-3'>
              <Logo
                logo={business?.logo}
                name={business?.name}
                storeLink={storeLink}
              />
              <div className='flex flex-col gap-3'>
                <h1
                  className={`z-10 whitespace-normal break-normal break-words text-left text-[40px] font-bold leading-10 text-[${mainColor}] smsize:w-full smsize:text-[30px]`}
                >
                  {business?.name}
                </h1>
                <div className='flex flex-col gap-1 text-left'>
                  <Address business={business} />
                  <PhoneNumber business={business} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className='flex-1 text-left text-[20px] font-light leading-7 line-clamp-4 smsize:w-full smsize:text-[16px] smsize:leading-5 smsize:line-clamp-5'
          dangerouslySetInnerHTML={{
            __html: business?.shortDesc || business?.description,
          }}
        />
        <div className='flex w-full items-center justify-start gap-3'>
          <GiftCardButton
            giftCardLink={business?.giftCardLink}
            storeLink={storeLink}
            bgColor={mainColor}
            borderColor={secondaryColor}
            textColor={secondaryColor}
            extraCss={'px-3'}
          />
          <ShopNowButton
            storeLink={storeLink}
            bgColor={secondaryColor}
            borderColor={mainColor}
            textColor={mainColor}
            extraCss={'px-3'}
          />
        </div>
      </div>
      {/* <div className='flex py-3'></div> */}
      <div className='flex w-full flex-col justify-start gap-5 py-3'>
        {business?.items?.map((item, index) => {
          return (
            <ItemCard
              key={index}
              item={item}
              mainColor={mainColor}
              secondaryColor={secondaryColor}
            />
          )
        })}
      </div>
    </PageTemplate>
  )
}
