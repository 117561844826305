import React from 'react'
import SSOLoginGoogle from './SSOLoginGoogle'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { Config } from '@Config'
import SSOLoginApple from './SSOLoginApple'

export default function SSOLogin({
    inviteUser,
    backurl
}) {

    return (
        <div>
            <div className='w-[340px] mb-5 flex items-center justify-center flex-col relative'>
                <div className='border-b w-full border-[#A6A6A6]'></div>
                <div className='px-4 bg-white absolute text-[#A6A6A6]'>Or</div>
            </div>
            <div className='w-[340px] flex items-center justify-center flex-col gap-4'>
                {/* <button className='h-[43px] border rounded-full border-[#747775] w-full flex items-center justify-center gap-3 text-[15px] font-medium text-center'>
                    <img src='/images/Google.png' alt='Sign up with Google' />Sign up with Google
                </button> */}

                <GoogleOAuthProvider clientId={Config.googleSSOClientId}>
                    <SSOLoginGoogle inviteUser={inviteUser} backurl={backurl} />
                </GoogleOAuthProvider>
                {/* <button className='h-[43px] border rounded-full border-[#747775] w-full flex items-center justify-center gap-3 text-[15px] font-medium text-center'>
                    <FaApple size={30} /> Sign up with Apple
                </button> */}
                <SSOLoginApple inviteUser={inviteUser} backurl={backurl} />
            </div>
        </div>
    )
}
