/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * The CarouselSection component displays a carousel of slides. Each slide is a different component
 * that provides various functionalities (HeroSection, FeatureStore, ShopNowPanel, JoinUs).
 * The carousel is responsive and adjusts the number of items displayed based on the screen size.
 * It also includes custom navigation arrows and autoplay functionality.
 *
 * @author James Nguyen, Yang Ming 
 * @version June 25 2024
 */

import React from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
// import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'
import { getCarousel } from '@services/api.service'
import { useQuery } from '@tanstack/react-query'
import MyImage from '@components/MyImage'
import './CarouselSection.css'

export default function CarouselSectionNew2025() {

  const {
    isPending,
    // error,
    data: carousels,
  } = useQuery({
    queryKey: ['getCarousel'],
    queryFn: getCarousel,
  })

  if (isPending) {
    return <div>Loading...</div>
  }

  console.log('carousels', carousels)

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }

  return (
    <div className='m-auto w-[1280px] my-5 smsize960:my-0 smsize:w-full'>
      <div className='w-full flex gap-5'>
        <Carousel
          containerClass='w-full'
          responsive={responsive}
          rtl={0}
          swipeable={true}
          showDots={false}
          arrows
          transitionDuration={500}
          autoPlay
          autoPlaySpeed={5000}
          infinite={true}
          removeArrowOnDeviceType={['tablet', 'mobile']}
        // itemClass='w-[100vw]'
        >
          {carousels?.filter((item) => item.isLarge === true)?.map((carousel) => (
            <a href={carousel.url} alt={carousel.title} key={carousel._id}>
              <MyImage src={carousel.image} alt={carousel.title} className='rounded-[20px] object-cover max-h-[720px] smsize:rounded-none' />
            </a>
          ))}
        </Carousel>
      </div>
    </div>
  )
}
