/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * ItemActionButton.js exports a React component named 'ItemActionButton'. This component displays a button with a tooltip.
 * The button text, tooltip text, click handler, disabled state, and class names are customizable.
 * 
 * The component takes five props: 'text', 'onClick', 'hoverText', 'disabled', and 'className'. 'text' is the button text. 'onClick' is the function that is called when the button is clicked.
 * 'hoverText' is the tooltip text. 'disabled' is a boolean indicating whether the button is disabled. 'className' is a string of class names to apply to the button.
 * 
 * @author Yang Ming, James Nguyen
 * @version January 18th, 2024
 */

import React from 'react'
import { Tooltip } from 'antd'

/**
 * Component that displays a button with a tooltip.
 * 
 * @param {Object} props - The props object.
 * @param {string} props.text - The button text.
 * @param {Function} props.onClick - The function that is called when the button is clicked.
 * @param {string} props.hoverText - The tooltip text.
 * @param {boolean} props.disabled - A boolean indicating whether the button is disabled.
 * @param {string} props.className - A string of class names to apply to the button.
 * @returns {JSX.Element} The 'ItemActionButton' component.
 */
export default function ItemActionButton({
    text,
    onClick,
    hoverText,
    disabled,
    className
}) {
    return (
        <Tooltip
            title={hoverText}
            placement="top"
            trigger={'hover'}
        >
            <button
                className={`flex h-[43px] w-full cursor-pointer items-center justify-center rounded-full border-2 px-3 py-2 text-center sm:w-[180px] sm:px-8 sm:py-3 ${className} whitespace-nowrap ${disabled ? 'bg-gray-300 hover:bg-gray-300 hover:text-white text-white cursor-not-allowed border-gray-300' : 'border-fitzba-bright-gold-static hover:border-fitzba-bright-gold-static'}`}
                onClick={onClick}
                disabled={disabled}
            >
                {text}
            </button>
        </Tooltip>
    )
}
