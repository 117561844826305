/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * Filter is a functional React component that renders various filters
 * for sorting and filtering the displayed list of items.
 *
 * @author Josh Soke, Yang Ming, James Nguyen
 * @version May 09, 2023
 */
import './Filter.css'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setFilterPrice,
  setFilterStore,
  setFilterBrand,
  setFilterDeliveryTypes,
  setFilterDistance,
  setFilterCondition,
  setFilterGiftGuide,
  // setFilterCategory,
  setFilterCategoryNew
} from '@reducers/contentReducer'
import FilterSidePrice from './FilterSidePrice'
import FilterSideStore from './FilterSideStore'
import FilterSideBrand from './FilterSideBrand'
import FilterSideLocation from './FilterSideLocation'
import FilterSideCategory from './FilterSideCategory'
import FilterSideDelivery from './FilterSideDelivery'
import FilterSideCondition from './FilterSideCondition'
import FilterSideCategoryNew from './FilterSideCategoryNew'
import FilterSideCategoryList from './FilterSideCategoryList'
import FilterSideGiftGuide from './FilterSideGiftGuide'

/**
 *
 * @returns Render a Filter sidebar to filter items
 */
export default function Filter({
  isMobile
}) {
  const dispatch = useDispatch()
  const itemsFilter = useSelector(state => state.content.itemsFilter)
  const filterList = useSelector(state => state.content.filterList)

  // Handle price filter change
  const onClickPrice = (min, max) => {
    dispatch(setFilterPrice({ priceMin: min, priceMax: max }))
  }

  return (
    <div className='filter-wrapper sticky' id='filter-wrapper'>
      <div className='mx-auto w-[300px] min-h-screen bg-fitzba-off-black-static px-10 font-lexend text-[15px] filter-content ' id="zearch-filter">
        <div className='filter-item mt-10'>
          <h3 className='text-[18px] font-[500]'>FILTERS</h3>
        </div>
        <FilterSideLocation
          itemsFilter={itemsFilter}
          dispatch={dispatch}
          setFilterDistance={setFilterDistance}
          isMobile={isMobile}
        />
        {/* <FilterSideCategory
          dispatch={dispatch}
          itemsFilter={itemsFilter}
          isMobile={isMobile}
        /> */}
        <FilterSideCategoryList
          dispatch={dispatch}
          itemsFilter={itemsFilter}
          isMobile={isMobile}
        />
        {/* <FilterSideCategoryNew
          itemsFilter={itemsFilter}
          dispatch={dispatch}
          setFilterCategory={setFilterCategoryNew}
          filterList={filterList}
          isMobile={isMobile}
        /> */}
        <FilterSideStore
          itemsFilter={itemsFilter}
          dispatch={dispatch}
          setFilterStore={setFilterStore}
          filterList={filterList}
          isMobile={isMobile}
        />
        {/* <FilterSideStoreNew
          itemsFilter={itemsFilter}
          dispatch={dispatch}
          setFilterStore={setFilterStoreNew}
          filterList={filterList}
          isMobile={isMobile}
        /> */}
        <FilterSideBrand
          itemsFilter={itemsFilter}
          dispatch={dispatch}
          setFilterBrand={setFilterBrand}
          filterList={filterList}
          isMobile={isMobile}
        />
        <FilterSideDelivery
          setFilterDeliveryTypes={setFilterDeliveryTypes}
          itemsFilter={itemsFilter}
          dispatch={dispatch}
          isMobile={isMobile}
        />

        <FilterSidePrice
          onClickPrice={onClickPrice}
          itemsFilter={itemsFilter}
          dispatch={dispatch}
          setFilterPrice={setFilterPrice}
          isMobile={isMobile}
        />
        {/* <FilterSideGiftGuide
          itemsFilter={itemsFilter}
          dispatch={dispatch}
          setFilterGiftGuide={setFilterGiftGuide}
          isMobile={isMobile}
        /> */}
        <FilterSideCondition
          itemsFilter={itemsFilter}
          dispatch={dispatch}
          setFilterCondition={setFilterCondition}
          isMobile={isMobile}
        />
      </div>
    </div>
  )
}
