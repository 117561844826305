/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * This module exports a React component named 'ItemHeader'. This component displays the header section of an item's detail page.
 * It includes the item's last updated time, name, brand, store information, live inventory status, and average rating.
 * 
 * The component takes two props: 'item' and 'isMobile'. 'item' is the item being displayed. 'isMobile' is a boolean indicating whether the device is mobile or not.
 * 
 * @author Yang Ming, James Nguyen
 * @version January 19th, 2024
 */

import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import ItemRating from './ItemRating'
import ItemStoreNameBox from './ItemStoreNameBox'
import ItemBrand from './ItemBrand'
import { getItemUpdatedTime } from '@utils/Helper'

/**
 * A component that displays the header section of an item's detail page.
 * 
 * @param {Object} props - The props object.
 * @param {Object} props.item - The item being displayed.
 * @param {boolean} props.isMobile - A boolean indicating whether the device is mobile or not.
 * @returns {JSX.Element} The 'ItemHeader' component.
 */
export default function ItemHeader({
  item,
  isMobile
}) {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  return (
    <div className='px-5 text-left'>
      <div className='text-[12px] text-[#ACACAC] font-light'>
        LAST UPDATED: {getItemUpdatedTime(item?.updatedAt)}
      </div>
      <h1 className='sm:leading-12 text-2xl leading-10 tracking-[1px] text-fitzba-black-static sm:text-3xl'>
        {item?.name}
      </h1>
      <div className='flex items-center gap-3'>
        <ItemBrand
          brandTitle={item?.brand?.title}
          navigate={navigate}
          dispatch={dispatch}
        />
        {/* {item?.is_gift_guide_item && (
          <div className='uppercase text-[12px] bg-fitzba-bright-gold-static rounded-md text-white py-1 px-2 font-light'>
            Gift Guide Item
          </div>
        )} */}
      </div>
      {/* store info */}
      <ItemStoreNameBox
        item={item}
        isMobile={isMobile}
        navigate={navigate}
        dispatch={dispatch}
      />
      {item?.isLiveInventory &&
        <div className='text-[13px] uppercase font-[400]'>Live Verified inventory</div>
      }
      {item?.review?.totalCount > 0 &&
        <ItemRating review={item.review} />
      }
    </div>
  )
}
